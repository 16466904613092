// CHECK IF USER HAS SCROLLED DOWN OR IS IS ON TOP
// $(window).scroll(function (event) {
//     var scroll = $(window).scrollTop();
//
//     if (scroll >= 100) {
//         $(".header-logo").addClass('logo-transform');
//     } else if (scroll <= 100) {
//         $(".header-logo").removeClass('logo-transform');
//     }
// });


// TOGGLE MENU
$(".hambuger-icon").click(function () {
    $("nav").toggleClass('nav-active');
});

$(".navigation-list li").click(function () {
    $("nav").removeClass('nav-active');
});

// Smooth Scroll
// $('a[href*="#"]')
//     // Remove links that don't actually link to anything
//     .not('[href="#"]')
//     .not('[href="#0"]')
//     .click(function (event) {
//         // On-page links
//         if (
//             location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
//             &&
//             location.hostname == this.hostname
//         ) {
//             // Figure out element to scroll to
//             var target = $(this.hash);
//             target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//             // Does a scroll target exist?
//             if (target.length) {
//                 // Only prevent default if animation is actually gonna happen
//                 event.preventDefault();
//                 $('html, body').animate({
//                     scrollTop: target.offset().top
//                 }, 1000, function () {
//                     // Callback after animation
//                     // Must change focus!
//                     var $target = $(target);
//                     $target.focus();
//                     if ($target.is(":focus")) { // Checking if the target was focused
//                         return false;
//                     } else {
//                         $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
//                         $target.focus(); // Set focus again
//                     }
//                 });
//             }
//         }
//     });
// $(function smoothscroll() {
//     setTimeout(function () {
//         if (location.hash) {
//             window.scrollTo(0, 0);
//             target = location.hash.split('#');
//             smoothScrollTo($('#' + target[1]));
//         }
//     }, 1);
//
//     function smoothScrollTo(target) {
//         target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//         if (target.length) {
//             $('html,body').animate({
//                 scrollTop: target.offset().top
//             }, 1000);
//         }
//     }
// });



// soft scroll function
var softscroll = function (clickelement) {
    clickelement.click(function (event) {
        var indexHref = $(this).attr("href");
        var indexHash = indexHref.split('#')[1];
        var $indexSelector = $("#" + indexHash);

        if ($indexSelector.length > 0) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $indexSelector.offset().top
            }, 850);

            // close navigation after selection
            if (checkMobileState == "block") {
                $('.mobile-nav').removeClass("act");
                $('.nav-fixed').fadeOut(300);
            }
        }
    });
}


// softscroll elements (navigation, actionboxes ...)
softscroll($('.softscroll'));






// scroll to the top
var scrolltop = $(window).scrollTop();
$(window).scroll(function () {
    var scrollvisibleheight = 1000;
    if ($(this).scrollTop() > scrollvisibleheight) {
        $('.to-top-arrow-container').fadeIn('slow');
    } else if ($(this).scrollTop() < scrollvisibleheight) {
        $('.to-top-arrow-container').fadeOut('slow');
    }
});
$('.to-top-arrow-container').click(function () {
    $('html, body').animate({scrollTop: 0}, 'slow');
    return false;
});


// Swipe

// get visible section
// jQuery.fn.isOnScreen = function()
// {
//     var win = jQuery(window);
//     var viewport = {
//         top : win.scrollTop(),
//         left : win.scrollLeft()
//     };
//     viewport.right = viewport.left + win.width();
//     viewport.bottom = viewport.top + win.height();
//     var bounds = this.offset();
//     bounds.right = bounds.left + this.outerWidth();
//     bounds.bottom = bounds.top + this.outerHeight();
//     return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
// };
//
//
// jQuery(window).scroll(function() {
//     if(jQuery('#element').isOnScreen())
//     {
//         console.log("dietz is on screen");
//     }
// })


// ---------------------------------------------------
// ---- Swipe Evangelische Stadtkirche Ravensburg ----
// ---------------------------------------------------
//
// var $SwipeAreaEva = $(".swipe");
//
// $SwipeAreaEva.each(function () {
//     var element = $(this);
//
//     // $(this).addEventListener('touchstart', handleTouchStartEva, false);
//     // el.addEventListener('touchmove', handleTouchMoveEva, false);
//
//     element.addClass("gregor-test");
//     element.on("touchstart", function () {
//         handleTouchStartEva($(this));
//     });
//     element.on("touchmove", function () {
//         handleTouchMoveEva($(this));
//     });
//
//     console.log($(this));
//
// });
//
//
// var xDown = null;
// var yDown = null;
//
// function getTouches(evt) {
//     console.log('getTouches');
//
//     return evt.touches ||             // browser API
//         evt.originalEvent.touches; // jQuery
// }
//
// function handleTouchStartEva(evt) {
//     console.log('getTouchStart');
//     const firstTouch = getTouches(evt)[0];
//     xDown = firstTouch.clientX;
//     yDown = firstTouch.clientY;
// }
//
// function handleTouchMoveEva(evt) {
    // var data_next = $(this).attr('data-next');
    // var data_prev = $(this).attr('data-prev');
    // console.log('data-next' + data_next);
    // console.log('data-prev' + data_prev);
    // if (!xDown || !yDown) {
    //     return;
    // }
    //
    // var xUp = evt.touches[0].clientX;
    // var yUp = evt.touches[0].clientY;
    //
    // var xDiff = xDown - xUp;
    // var yDiff = yDown - yUp;
    //
    // if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
    //     if (xDiff > 0) {
    //         /* right swipe */
    //         window.location = data_next;
    //     } else {
    //         /* left swipe */
    //         window.location = data_prev;
    //     }
    // }
    // /* reset values */
    // xDown = null;
    // yDown = null;
// }

// ---------------------
// ---- Swipe Dietz ----
// ---------------------
//
//
// var SwipeAreaDietz = document.querySelector("#dietz");
//
// SwipeAreaDietz.addEventListener('touchstart', handleTouchStartDietz, false);
// SwipeAreaDietz.addEventListener('touchmove', handleTouchMoveDietz, false);
//
// var xDown = null;
// var yDown = null;
//
// function getTouches(evt) {
//     console.log('getTouches');
//
//     return evt.touches ||             // browser API
//         evt.originalEvent.touches; // jQuery
// }
//
// function handleTouchStartDietz(evt) {
//     const firstTouch = getTouches(evt)[0];
//     xDown = firstTouch.clientX;
//     yDown = firstTouch.clientY;
// }
//
// function handleTouchMoveDietz(evt) {
//     if (!xDown || !yDown) {
//         return;
//     }
//
//     var xUp = evt.touches[0].clientX;
//     var yUp = evt.touches[0].clientY;
//
//     var xDiff = xDown - xUp;
//     var yDiff = yDown - yUp;
//
//     if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
//         if (xDiff > 0) {
//             /* right swipe */
//             window.location = "http://weber-bauunternehmen.de/weber-bauunternehmen/projekte/dietz/";
//         } else {
//             /* left swipe */
//             window.location = "http://weber-bauunternehmen.de/weber-bauunternehmen/#dietz";
//         }
//     }
//     /* reset values */
//     xDown = null;
//     yDown = null;
// }